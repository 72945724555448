
$background: #f7f7f7;
$nav-background: rgba(255, 255, 255, 1);
$background-primary: #fff;

$side-menu-width: 250px;
$nav-bar-height: 80px;

$default-box: 0 0 10px rgba(0, 0, 0, 0.25);
$hover-box: 0 0 5px rgba(0, 0, 0, 0.15);
$corners: 10px;

$font-primary: "Helvetica Neue", sans-serif;
$font-size-button: 13pt;
$font-size-title: 23pt;

$border: 1px solid #D0DAED;

$box-background-color: #fff;

$text-title: #555;
$text-body: #666;

$primary: #1592E6;
$accent: #4258a7;
$secondary: #fff;
$success: #006a77;
$warning: #f58058;
$danger: #f52f2a;
$info: #1592E6;
$tertiary:#EAF6FF;
$divider: #DDDDDD;

$light-grey: #f0eded;
$green: #1A8A00;
$dark-green: #006a77;

$text-dark: #292B2C;
$text-light: #777777;
$background-selected: #0275d8
