@import 'variables';
@import 'mixins';

body {
    background-color: $background;
}

.btn {
    border-radius: $corners;
    padding: 10px 20px;
}

.btn:hover {
    cursor: pointer;
}

.tpi-primary {
    color: $info;
}

.tpi-secondary {
    color: $secondary;
}

.tpi-success {
    color: $success;
}

.tpi-warning {
    color: $warning;
}

.tpi-danger {
    color: $danger;
}

.btn-primary {
    background: $primary;
}

.btn-secondary {
    background: $secondary;
    // margin-left: 10%;
}

.btn-info {
    background: $info;
}

.btn-success {
    background: $success;
}
.btn-warning {
    background: $warning;
}
.btn-danger {
    background: $danger;
}

.btn-outline-primary {
    border-color: $info;
    color: $info;
}

.btn-outline-primary:hover {
    background: $info;
}

.btn-outline-secondary {
    border-color: #fff;
    color: #fff;
}

.btn-outline-secondary:hover {
    background: #fff;
    color: $success;
}

.btn-outline-info {
    border-color: $info;
    color: $info;
}

.btn-outline-info:hover {
    color: #fff;
    background: $info;
}

.btn-outline-success {
    border-color: $success;
    color: $success;
}

.btn-outline-success:hover {
    color: #fff;
    background: $success;
}

.btn-outline-warning {
    border-color: $warning;
    color: $warning;
}

.btn-outline-warning:hover {
    color: #fff;
    background: $warning;
}

.btn-outline-danger {
    border-color: $danger;
    color: $danger;
}

.btn-outline-danger:hover {
    color: #fff;
    background: $danger;
}

.btn-danger:focus, .btn-danger:active {
    color: #fff;
    text-decoration: none;
    background: $danger;
}

.btn-primary:hover, .btn-icon:focus {
    background: $primary;
}

.btn-secondary:hover, .btn-icon:focus {
    background: $secondary;
}

.btn-info:hover, .btn-icon:focus {
    background: $info;
}

.btn-success:hover, .btn-icon:focus {
    background: $success;
}
.btn-warning:hover, .btn-icon:focus {
    background: $warning;
}
.btn-danger:hover, .btn-icon:focus {
    background: $danger;
}

.btn-icon {
    background: transparent;
    box-shadow: none;
}

.btn-toast {
    box-shadow: none;
    font-size: 11pt;
    text-transform: capitalize;
    color: $primary;
}

.btn-toast:hover, .btn-toast:focus {
    box-shadow: none;
}

.btn-toast.btn-secondary {
    color: $text-body;
}

.btn-icon:hover, .btn-icon:focus {
    background: transparent;
    box-shadow: none;
}

.tpi-heading {
    font-size: $font-size-title;
    font-family: $font-primary;
    color: $text-title;
    font-weight: 500;
    //border-bottom: $border;
    padding: 35px 5px 20px 5px;
    margin: 10px 0;
}

.tpi-heading .btn,
.tpi-heading .btn-group {
    margin-left: 10px;
}

.tpi-heading .dropdown-toggle::after {
    border: none;
    margin-left: 0;
}

.tpi-heading-btn {

}

.tpi-heading .dropdown-toggle::after {

}

.tpi-icon-heading {
}

.tpi-sub-heading  {
    color: #555555;
    float: right;
}

.tpi-icon-heading .tpi-icon {
    font-size: 32pt;
    margin-top: 40px;
    color: $primary;
}

.tpi-icon-heading h3 {
    margin-top: 28px;
}

.tpi-icon-heading p {
    margin-bottom: 25px;
}

.tpi-guide h4 {
    font-size: 14pt;
    padding: 15px;
    font-weight: 300;
    color: $text-title;
}

.tpi-guide p {
    margin-top: 15px;
}

.tpi-border {
    height: 1px;
    background: $primary; /* Old browsers */
    background: -moz-linear-gradient(left, $primary 0%, $accent 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, $primary 0%,$accent 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, $primary 0%,$accent 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$primary', endColorstr='$accent',GradientType=1 ); /* IE6-9 */
}

.tpi-box {
    background-color: $box-background-color;
    border-radius: $corners;
    box-shadow: $default-box;
}

.tpi-box-content {
    padding: 35px;
}

.tpi-product-image {
    height: 85px;
    display: block;
    margin-bottom: -10px;
    margin-right: 10px;
}

.tpi-heading-content {
    display: inline-block;
    vertical-align: top;
}

.tpi-heading-content h3, .tpi-heading-content p {
    margin: 0;
}

.tpi-heading-content h3 {
    font-size: 17pt;
    margin-bottom: .5rem;
}

.tpi-heading-content p {
    font-size: 12pt;
}

.tpi-product-image img {
    height: 70px;
    display: inline-block;
    margin-left: 15px;
}

.tpi-product-header {
    display: block;
    height: 85px;
    vertical-align: super;
    font-size: 16pt;
}

.tpi-product-title {
    font-size: 16pt;
    color: $primary;
    margin-bottom: 0;
}

.tpi-product-category {
    font-size: 10pt;
    color: #888;
}

.tpi-product-short {
    font-size: 10pt;
}

.tpi-grid-item {
    box-shadow: $default-box;
    background: $background-primary;
    border-radius: $corners;
    padding: 15px 0;
    border: 1px solid transparent;
    transition: all .2s ease-in-out;
}

.tpi-grid-item:hover {
    box-shadow: $hover-box;
    cursor: pointer;
}

.tpi-grid-item.active {
    background: #f0f8ff;
    border: 1px solid $info;
}

.tpi-grid-item .tpi-border {
    margin: 10px 0;
    height: 3px;
}

.tpi-product-content {
    padding: 15px;
    text-align: center;
    color: $text-body;
}

.tpi-product-content i {
    display: block;
}

.tpi-product-content .tpi-product-extra {
    margin-top: 25px;
}

.tpi-product-content .tpi-product-extra p {
    font-size: 14px;
    font-weight: 500;
    color: $primary;
    text-transform: uppercase;
    border: solid 1px $primary;
    background-color: $tertiary;
    border-radius: 5px;
    padding: 5px 0px;
}

.tpi-price {
    color: $danger;
    font-weight: 600;
}

.price-prefix{
    font-size: 12px;
    width: 100%;
}

.tpi-currency {
    font-weight: 500;
}

.tpi-per {
    font-weight: 200;
    color: $text-body;
}

.tpi-product-content .tpi-per {
    font-size: 9pt;
}

.grid {
    margin: 0 -3px;
}

.grid-col {
    padding: 3px;
    width: 24.6%;
    display: inline-block;
    margin-bottom: 3px;
}

.tpi-nav-padding {
    width: 100%;
    height: $nav-bar-height;
}

.tpi-editable {
    border: 2px dashed $primary;
    background: #edf3f8;
    border-radius: 10px;
    padding: 14px 20px;
    color: $primary;
    font-size: 20px;
    height: 60px;
    font-weight: 600;
    line-height: 30px;
}

.tpi-editable i {
    font-size: 30px;
    line-height: 30px;
    margin-right: 10px;
}

.tpi-editable-success {
    border: 2px dashed $success;
    background: #E6F6F5;
    color: $success;
}

.tpi-editable-danger {
    border: 2px dashed $danger;
    background: #EFD5D5;
    color: $danger;
}

.tpi-editable:hover, .tpi-editable:focus, .tpi-editable:active {
    filter: brightness(105%);
    cursor: pointer;
    border-style: dashed;
    outline: none;
}

.sticky-next {
    position: fixed;
    background: rgba(28, 140, 224, 0.1);
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    z-index: 10;
    border-top: 1px solid rgba(28, 141, 225, 0.3);
}

.side-menu-show .sticky-next {
    margin-left: 250px;
}

.tpi-multi-select .mat-select-value-text {
    white-space: normal;
    overflow: auto;
}

.tpi-multi-select .mat-select-value {
    overflow: auto;
    white-space: normal;
}

.multi-select-item {
    background: #c7e1ff;
    color: #1d5592;
    padding: 5px 15px;
    line-height: 34px;
    border-radius: 5px;
    margin-right: 10px;
    border: 1px solid #3a78c3;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
}

.country-code {
    width: 90px !important;
    padding-top: 15px;
}

.country-code .mat-select-trigger {
    padding: 5px 7px;
}
.country-flag-icon {
    height: 15px;
}

.country-flag {
    position: absolute;
    margin-top: 21px;
    z-index: 1;
    width: 40px;
    margin-left: 8px;
    pointer-events: none;
    border-radius: 3px;
    border: 1px solid #adadad;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
}

.country-flag.contact-number-img {
    position: static;
    margin-top: 0;
}

.obfuscated {
    font-size: 17pt;
    font-weight: 300;
    letter-spacing: 11px;
    color: #888;
}

.obfuscated-input {
    width: 50px;
    font-size: 19pt;
    font-weight: bold;
    text-align: center !important;
}

.vehicle-detail {
    padding: 20px;
    margin-bottom: 10px;
    background: #fff;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
    border-top: 1px solid #e8e8e8;
}

.user-subscription {
    padding-left: 10px;
    padding-bottom: 10px;
    background: #fff;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
    border-top: 1px solid #e8e8e8;
}

.vehicle-field {
    font-size: 10pt;
}

.vehicle-value {
    color: #999;
    float: right;
}

.unlink-button {
    text-align: right;
    width: 100%;
    display: block;
}

.text-small {
    font-size: 9pt;
}

.underwriter-title {
    float: none;
    text-align: center;
    padding: 0 15px;
}

.underwriter-title h6 {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    max-height: 36px;
    overflow: hidden;
    height: 40px;
}

.underwriter-title p {
    font-size: 9pt;
    color: #888;
    margin: 0;
}

.font-tiny {
    font-size: 9pt;
    color: #888;
}

.tpi-product-rows {
    border-radius: 10px;
    padding: 5px 25px 8px 25px;
    background: #f8f8f8;
}

.tpi-product-row {
    border-radius: 10px;
    padding: 10px 0;
    background: #f2f2f2;
    border: $border;
}

.tpi-product-row .tpi-editable {
    height: 45px;
    padding: 5px 15px;
    border-radius: 22px;
    width: 60px;
    text-align: center;
}

.tpi-product-row .tpi-editable i {
    font-size: 25px;
    line-height: 33px;
}

.tpi-ellipsis {
    height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tpi-three-rows {
    max-height: 75px;
    min-height: 50px;
    overflow: hidden;
}

.tpi-product-divider {
    background: #D9D9D9;
    height: 1px;
    margin-top: 35px;
    margin-bottom: 35px;
}

.excess-container {
    background: #f2f2f2;
    border-radius: 15px;
    border: 1px solid #ddd;
    margin-bottom: 15px;
    padding: 15px;
}

@media (max-width: 1280px) {

    .grid-col {
        width: 32.9%;
    }

}

@media (max-width: 1024px) {

    .grid-col {
        width: 49.5%;
    }

}

@media (max-width: 600px) {

    .grid-col {
        width: 100%;
    }

}

@media (max-width: 600px) {
    .tpi-box-content {
        padding: 30px 0px;
    }
    .tpi-box {
        background-color: transparent;
        border-radius: 0;
        box-shadow: none;
    }
}

@media (max-width: 992px) {
    .tpi-heading {
        font-size: 17pt;
        padding: 15px 0;
        line-height: 40px;
        text-align: left !important;
    }
    .sticky-next .description {
        display: none;
    }
    .sticky-next button {
        float: left !important;
    }
    .sticky-next .btn-primary {
        margin-left: 15px;
    }
}
