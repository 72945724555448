@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import 'app/common/sass/variables';
@import 'app/common/sass/mixins';
@import 'app/common/sass/tpi';

html, body {
    height: 100vh;
    background-color: $background;
    overflow-x: hidden;
}

p{
    color: #777;
}

.how-it-works-text {
    color: #777;
    padding-left: 15px;
}

.input-search {
    width: auto;
    margin-top: 4px;
    margin-left: 15px;
}

.btn-toast.btn-primary {
    color: $primary !important;
    background: transparent !important;
}

.toast-progress {
    background: $primary; /* Old browsers */
    background: -moz-linear-gradient(left, $primary 0%, $accent 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, $primary 0%,$accent 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, $primary 0%,$accent 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$primary', endColorstr='$accent',GradientType=1 ); /* IE6-9 */
    opacity: 1;
    height: 2px;
}

.ngx-datatable.material .datatable-header {
    border-bottom: none;
    height: auto !important;
}

.ngx-datatable.material {
    background: transparent;
    box-shadow: none;
    overflow: visible;
}

.ngx-datatable.material .datatable-footer {
    border-top: none;
    overflow: hidden;
}

.ngx-datatable.material .datatable-body .datatable-body-row {
    box-shadow: $default-box;
    background: $background-primary;
    margin: 2px 3px;
    border-radius: $corners;
}

.policy-query .ngx-datatable.material .datatable-body .datatable-body-row {
    box-shadow: none;
    background: #f5f5f5;
    border: 1px solid #e2e2e2;
}

.ngx-datatable.material .datatable-body .datatable-body-row:hover {
    cursor: pointer;
    background: $background-primary;
    box-shadow: $hover-box;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    padding: 1rem 1.5rem;
}

.ngx-datatable.material.single-selection .datatable-body-row.active, .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active, .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
    background-color: #f2f2f2;
}

.ngx-datatable.material.single-selection .datatable-body-row.active:hover, .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
    background-color: #f2f2f2 !important;
}

.no-hover .ngx-datatable.material .datatable-body .datatable-body-row:hover {
    background: #f5f5f5;
    border: 1px solid #e2e2e2;
    box-shadow: none;
}

.hidden-overlflow .datatable-body-cell {
    overflow: hidden;
}


.dropdown-item:hover {
    cursor: pointer;
}

.backdrop, .spinner-three-bounce {
    position: fixed !important;
}

.hidden-datatable datatable-body, .hidden-datatable datatable-header {
    display: none !important;
}

.btn-group>.btn.active:not(.dropdown-toggle) {
    background: $info;
    color: #fff;
}

.side-menu-show {
    margin-left: $side-menu-width;
    transition: all 0.3s;
}

.alert .btn {
    height: 30px;
    line-height: 10px;
    font-size: 10pt;
}

.dropdown-item {
    padding: 7px 1.5rem;
}

html.cdk-global-scrollblock, html.cdk-global-scrollblock body {
    overflow-y: hidden;
}

.cdk-global-overlay-wrapper {
    overflow-y: scroll;
}

.cdk-global-scrollblock .cdk-global-overlay-wrapper, .cdk-global-scrollblock .cdk-overlay-container {
    pointer-events: initial;
}

@media (max-width: 768px) {
    .side-menu-show {
        margin-left: 60px;
    }
}

.year-picker .mat-calendar-period-button {
    pointer-events: none;
}

.year-picker .mat-calendar-arrow {
    display: none;
}

.tpi-product-description {
    font-size: 12pt;
    background: #ffffff;
    padding: 10px 20px;
    margin-top: 5px;
    border: 1px solid #ddd;
    word-wrap: break-word;
    margin-bottom: 10px;
    border-radius: 5px;
}

.tpi-policy-item-row {
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 15px;
}

.tpi-policy-item-row:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.tpi-product-value {
    font-weight: 500;
}

:root {
  --unit-1: 4px;
  --elevation-1: 1;
  --min-height-carousel: calc(var(--unit-1) * 50);
  --color-brand-1: grey;
}

.tooltip-inner {

    max-width: 270px;
    padding: 15px;
    text-align: center;
    color: $text-title;
    background-color: $background;
    border-radius: 5px;
    box-shadow: $hover-box;

}

.tooltip.tooltip-top .tooltip-inner::before, .tooltip.bs-tether-element-attached-bottom .tooltip-inner::before {
    border-top-color: $background;
}

.tooltip.tooltip-right .tooltip-inner::before, .tooltip.bs-tether-element-attached-left .tooltip-inner::before {
    border-right-color: $background;
}

.tooltip.tooltip-bottom .tooltip-inner::before, .tooltip.bs-tether-element-attached-top .tooltip-inner::before {
    border-bottom-color: $background;
}

.tooltip.tooltip-left .tooltip-inner::before, .tooltip.bs-tether-element-attached-right .tooltip-inner::before {
    border-left-color: $background;
}

.datatable-row-detail {
    margin: -14px -13px 16px -7px;
    background: transparent !important;
}

.text-success {
    color: #006a77 !important;
    border-radius: 15px;
}

.period-filter-value{
    display:none;
    font-size: 12px;
    position: absolute;
    bottom: 0px;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 2px;
    padding-left: 10px;
}

.disabledLink {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
}

.period-filter-show{display: block;}

.period-filter-remove-button{
    background:none;
    border:none;
}
.period-filter-remove-button:hover{
    background-color: #e0e0e0;
    border-radius: 5px;
    cursor: pointer;
}

.mat-tab-body-content{
    overflow:hidden !important;
}

.browser-icon, .browser-icon:visited {
    display: inline-block;
    text-align: center;
    color: #000 !important;
    font-size: 10pt;
    background: #f2f2f2;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin: 5px;
    padding: 10px 10px;
}

.browser-icon:active, .browser-icon:hover, .browser-icon:focus {
    color: #333;
}

.browser-icon img {
    max-width: 70px;
    margin: 10px;
}

.browser-icons {
    margin-top: 25px;
    text-align: center;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    position: fixed;
    top: 80px;
    right: 10px;
    z-index: 99;
    width: 370px;
    padding: 20px 15px;
}

.browser-icons h4 {
    margin-bottom: 20px;
}

.browser-icons i {
    font-size: 24pt;
    color: #ffbc00;
    margin-bottom: 10px;
}

.product-dialog .mat-dialog-container, .vehicles-dialog .mat-dialog-container {
    padding: 0;
    border-radius: 15px;
}

.policy-details-dialog {
    height: calc(100vh - 75px);
    border-radius: 5px;
}

.proposal-overlay-panel {
    .mat-mdc-option.mdc-list-item {
        padding: 0 8px;
    }
}

@media (max-width: 992px) {

    .input-search {
        width: 160px;
        position: absolute;
        margin-top: -45px;
        line-height: 2;
        z-index: 9;
        margin-left: -10px;
    }

}

@media (max-width: 768px) {
    .mat-dialog-container {
        padding: 8px;
    }
    .hide-mobile {
        display: none;
    }
    .mobile-left-align {
        text-align: left;
    }
    .mobile-left-align button, .mobile-left-align .btn, .mobile-left-align .btn-group {
        float: left;
        margin-bottom: 10px;
    }
    .mobile-left-align .btn-primary {
        margin-left: 10px;
    }
    .mobile-no-padding {
        padding: 0;
    }
    .mobile-block-buttons button {
        display: block;
        margin-bottom: 10px;
        width: 100%;
    }

    .type-buttons{
        width: auto;
    }
}

@media (max-width: 768px) {
    .mat-dialog-container {
        padding: 15px !important;
    }
}

.declaration .mat-checkbox-layout {
    margin-bottom: 2px;
}

.alert-container .tpi-nav-padding {
    margin-bottom: 50px;
}

.alert-container #side-menu {
    margin-top: 50px;
}

.mat-accent .mat-slider-thumb, .mat-accent .mat-slider-thumb-label, .mat-accent .mat-slider-track-fill {
    background-color: $primary;
}

.mat-slider-horizontal .mat-slider-wrapper {
    height: 4px !important;
    top: 22px !important;
}

.mat-slider-horizontal .mat-slider-track-wrapper, .mat-slider-track-background, .mat-slider-track-fill {
    height: 4px !important;
}

.mat-slider-thumb {
    transform: scale(1) !important;
}

.excess-input {
    font-weight: 600;
    color: $danger;
    text-align: right !important;
    margin-top: -40px;
    margin-bottom: -50px;
}

.excess-input .text-currency {
    color: #333;
    font-weight: 500;
}

.excess-input .mat-form-field-flex {
    background: transparent;
}

.country-select .mat-select-trigger {
    position: absolute;
    top: 7px;
    left: 0;
    width: 55px;
    height: 40px;
}

.policy-view {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    overflow-y: scroll;
    z-index: 50;
    padding: 50px 0;
}

.policy-view-container {
    max-width: 950px;
    margin: auto;
    background: #fff;
    border-radius: 15px;
    padding: 35px;
}

.padded-modal .mat-mdc-dialog-surface {
    padding: 35px 20px;
}

.reports-table {
    width: 100%;
    background-color: $box-background-color;
    border-radius: $corners;

    th {
        z-index: 0 !important;
    }

    th, td {
        min-width: 150px;
        border: none;
    }

    .scroll {
        max-height: 600px;
        overflow: auto;
        border-radius: 15px 15px 0 0;
    }

    mat-paginator {
        border-radius: 0 0 15px 15px;
    }

    table {
        width: 100%;
    }

    .headings-row {
        background: $primary !important;
        color: #fff;
    }

    tbody tr:nth-child(odd) {
        background-color: #eee !important;
    }

    .mat-header-cell {
        padding: 0;
        margin: 0;
    }

    .mat-header-row {
        border-spacing: 0;
    }
}


@media (max-width: 600px) {
    .responsive-table .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
        padding: 10px;
        font-size: 13px;
    }
    .cdk-overlay-pane {
        width: 100% !important;
        margin-top: 0 !important;
        transform: none !important;
    }
    .mat-option-text {
        font-size: 10pt;
    }
}

//Angular 15 styling fixes
.mat-mdc-dialog-actions{
    justify-content: flex-end !important;
}
